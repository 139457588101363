import React from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../Logo'

export default function index() {
    return (
        <>
            <Container>
                <Navbar expand="lg" bg="dark" data-bs-theme="dark">
                    <Container className=' p-0'>
                        <Navbar.Brand href="/"><Logo /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav className="me-auto my-2 my-lg-0 navbar-nav-md-scroll" >
                                <Nav.Link href="/home">Home</Nav.Link>
                                <Nav.Link href="/matches">Matches</Nav.Link>
                                <Nav.Link href="/clubs">Clubs</Nav.Link>
                                <Nav.Link href="/discussions">Discussions</Nav.Link>
                                <Form.Control type="search" placeholder="Search Match" className="me-2" aria-label="Search" />
                            </Nav>
                            <Form className="d-flex">
                                <Button as="a" className='grey-btn' href="/login">
                                    Login / Register
                                </Button>
                            </Form>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Container>
        </>
    )
}
