import React from 'react'
import Header from '../../common/Header'
import Livematch from './Livematch'
import Highlight from './Highlight'
import Toppicks from './Toppicks'
import Copyright from '../../common/Copyright'
import Footer from '../../common/Footer'

export default function Index() {
    return (
        <>
            <Header />
            <Livematch />
            <Highlight />
            <Toppicks />
            <Footer />
            <Copyright />
        </>
    )
}
