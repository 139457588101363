import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export default function index() {
    return (
        <>
            <Container>
                <div className="text-white p-3">
                    <Row>
                        <Col md={3}></Col>
                        <Col md={6}>
                            <h3 className="text-center mt-3 mb-3">
                                Signup
                            </h3>
                            <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="name@example.com" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" placeholder="name@example.com" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" placeholder="*******" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control type="password" placeholder="*******" />
                                </Form.Group>
                            </Form>
                            <div className="text-center mt-3">
                                <Button variant="primary" href="/home">Sign Up</Button>
                            </div>
                            <div className="text-center mt-3">
                                <p>Have an Account? <a href="/login" variant="" className='text-decoration-none' >
                                    Login
                                </a></p>
                            </div>
                        </Col>
                        <Col md={3}></Col>
                    </Row>
                </div>
            </Container>
        </>
    )
}
