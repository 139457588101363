import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Components/Pages/Home'
import Discussions from '../src/Components/Pages/Discussions/index'
import Clubs from './Components/Pages/Clubs'
import Matches from './Components/Pages/Matches'
import Login from './Components/Pages/Login'
import Signup from './Components/Pages/Signup'
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} ></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/matches" element={<Matches />} ></Route>
          <Route path="/clubs" element={<Clubs />} ></Route>
          <Route path="/discussions" element={<Discussions />} ></Route>
          <Route path="/login" element={<Login />} ></Route>
          <Route path="/signup" element={<Signup />} ></Route>
        </Routes>
      </BrowserRouter >
    </>
  );
}

export default App;
