import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './matches.css'


export default function index() {
    return (
        <>
            <Container className='matches-tabs'>
                <Row>
                    <Col>
                        <Tabs defaultActiveKey="live" id="justify-tab-example" className='mb-1' justify>
                            <Tab eventKey="live" title="Live">
                                <Row className='mb-1 gx-lg-1 gx-1 gy-lg-0 gy-1'>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mb-1 gx-lg-1 gx-1 gy-lg-0 gy-1'>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mb-1 gx-lg-1 gx-1 gy-lg-0 gy-1'>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="upcoming" title="Upcoming">
                                <Row className='mb-1 gx-lg-1 gx-1 gy-lg-0 gy-1'>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mb-1 gx-lg-1 gx-1 gy-lg-0 gy-1'>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mb-1 gx-lg-1 gx-1 gy-lg-0 gy-1'>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="event" title="Event" >
                                <Row className='mb-1 gx-lg-1 gx-1 gy-lg-0 gy-1'>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mb-1 gx-lg-1 gx-1 gy-lg-0 gy-1'>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mb-1 gx-lg-1 gx-1 gy-lg-0 gy-1'>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6}>
                                        <div className="bg-white p-3">
                                            <div className="">
                                                <h6 className="">Final Match</h6>
                                                <Row>
                                                    <Col>
                                                        <img alt="flag" src="img/new-zealand.png" />&nbsp;&nbsp;<span>GIB</span>
                                                    </Col>

                                                    <Col>
                                                        <span>150-8</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <img alt="flag" src="img/armenia.png" />&nbsp;&nbsp;<span><b>MAR</b></span>
                                                </Col>
                                                <Col>
                                                    <span><b>150-8</b></span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> <span className="text-primary">Lorem Ipsum Dolor
                                                    Imtet</span></Col>

                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
