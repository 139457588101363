import React from 'react'
import './copyright.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

export default function index() {
    return (
        <>
            <Container fluid >
                {/* <Row className="bg-dark p-1"> */}
                <Row className=" p-1">
                    <div className='borbot'></div>
                    <p className="m-0 text-center text-grey">Copyright 2024 @ Cricket</p>
                </Row>
            </Container>
        </>

    )
}
