import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import './livematch.css'

export default function index() {
    return (
        <>
            <Container>
                <Row className="gx-4 mt-4">
                    <Col lg={6} className='posrel'>
                        <video style={{ width: 100 + '%' }}>
                            <source src="img/video.mp4" />
                            <source src="" type="video/ogg" />
                            Your browser does not support the video tag.
                        </video>
                        <div className="posabs">
                            <h2 className="m-0">Live Match (215)</h2>
                            <p className="secondary p-0 ">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore </p>
                            <Button as="a" className='grey-btn' href="#">
                                Subscribe to Watch
                            </Button>
                        </div>
                    </Col>
                    <Col lg={6} className='posrel'>
                        <video style={{ width: 100 + '%' }}>
                            <source src="img/video.mp4" />
                            <source src="" type="video/ogg" />
                            Your browser does not support the video tag.
                        </video>
                        <div className="posabs">
                            <h2 className="m-0">Completed Match (215)</h2>
                            <p className="secondary p-0 ">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore </p>
                            <Button as="a" className='grey-btn' href="#">
                                Subscribe to Watch
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
