import React from 'react'
import './highlight.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function index() {
    return (
        <>
            <Container>
                {/* <div className='bg-white p-3'> */}
                <div className='text-white pt-3'>
                    <Row>
                        <h4>Highlights</h4>
                        <Col lg={3} md={6} className='posrel'>
                            <video style={{ width: 100 + '%' }}>
                                <source src="img/video.mp4" />
                                <source src="" type="video/ogg" />
                                Your browser does not support the video tag.
                            </video>
                            <div className="posabs">
                                <h5 className="m-0">
                                    15 May CSK vs RCB
                                </h5>
                                <p className="m-0">Lorem ipsum dolor imtetv picl..</p>
                            </div>
                        </Col>
                        <Col lg={3} md={6} className='posrel'>
                            <video style={{ width: 100 + '%' }}>
                                <source src="img/video.mp4" />
                                <source src="" type="video/ogg" />
                                Your browser does not support the video tag.
                            </video>
                            <div className="posabs">
                                <h5 className="m-0">
                                    15 May CSK vs RCB
                                </h5>
                                <p className="m-0">Lorem ipsum dolor imtetv picl..</p>
                            </div>
                        </Col>
                        <Col lg={3} md={6} className='posrel'>
                            <video style={{ width: 100 + '%' }}>
                                <source src="img/video.mp4" />
                                <source src="" type="video/ogg" />
                                Your browser does not support the video tag.
                            </video>
                            <div className="posabs">
                                <h5 className="m-0">
                                    15 May CSK vs RCB
                                </h5>
                                <p className="m-0">Lorem ipsum dolor imtetv picl..</p>
                            </div>
                        </Col>
                        <Col lg={3} md={6} className='posrel'>
                            <video style={{ width: 100 + '%' }}>
                                <source src="img/video.mp4" />
                                <source src="" type="video/ogg" />
                                Your browser does not support the video tag.
                            </video>
                            <div className="posabs">
                                <h5 className="m-0">
                                    15 May CSK vs RCB
                                </h5>
                                <p className="m-0">Lorem ipsum dolor imtetv picl..</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    )
}
