import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function index() {
    return (
        <>
            <Container>
                <Row className='gx-2 '>
                    <Col>
                        {/* <div className='p-3 bg-white'> */}
                        <div className='pt-3 text-white' >
                            <h4 className="m-0">Clubs</h4>
                            <Row>
                                <Col md={4}>
                                    <div className=" pt-3 pb-3 ps-4 pe-4">
                                        <p className="secondary m-0 ">Chennai Super Kings</p>
                                        <div className='borbot'></div>
                                    </div>
                                    <div className=" pt-3 pb-3 ps-4 pe-4">
                                        <p className="secondary m-0 ">Chennai Super Kings</p>
                                        <div className='borbot'></div>
                                    </div>
                                    <div className=" pt-3 pb-3 ps-4 pe-4">
                                        <p className="secondary m-0 ">Chennai Super Kings</p>
                                        <div className='borbot'></div>
                                    </div>
                                    <div className=" pt-3 pb-3 ps-4 pe-4">
                                        <p className="secondary m-0 ">Chennai Super Kings</p>
                                        <div className='borbot'></div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className=" pt-3 pb-3 ps-4 pe-4">
                                        <p className="secondary m-0 ">Chennai Super Kings</p>
                                        <div className='borbot'></div>
                                    </div>
                                    <div className=" pt-3 pb-3 ps-4 pe-4">
                                        <p className="secondary m-0 ">Chennai Super Kings</p>
                                        <div className='borbot'></div>
                                    </div>
                                    <div className=" pt-3 pb-3 ps-4 pe-4">
                                        <p className="secondary m-0 ">Chennai Super Kings</p>
                                        <div className='borbot'></div>
                                    </div>
                                    <div className=" pt-3 pb-3 ps-4 pe-4">
                                        <p className="secondary m-0 ">Chennai Super Kings</p>
                                        <div className='borbot'></div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className=" pt-3 pb-3 ps-4 pe-4">
                                        <p className="secondary m-0 ">Chennai Super Kings</p>
                                        <div className='borbot'></div>
                                    </div>
                                    <div className=" pt-3 pb-3 ps-4 pe-4">
                                        <p className="secondary m-0 ">Chennai Super Kings</p>
                                        <div className='borbot'></div>
                                    </div>
                                    <div className=" pt-3 pb-3 ps-4 pe-4">
                                        <p className="secondary m-0 ">Chennai Super Kings</p>
                                        <div className='borbot'></div>
                                    </div>
                                    <div className=" pt-3 pb-3 ps-4 pe-4">
                                        <p className="secondary m-0 ">Chennai Super Kings</p>
                                        <div className='borbot'></div>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
