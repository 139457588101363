import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function index() {
    return (
        <>
            <Container>
                {/* <div className='bg-white p-3'> */}
                <div className='text-white pt-3'>
                    <Row>
                        <h4>Top Picks</h4>
                        <Col lg={3} md={6}>
                            <video style={{ width: 100 + '%' }} controls >
                                <source src="img/video.mp4" />
                                <source src="" type="video/ogg" />
                                Your browser does not support the video tag.
                            </video>
                        </Col>
                        <Col lg={3} md={6}>
                            <video style={{ width: 100 + '%' }} controls >
                                <source src="img/video.mp4" />
                                <source src="" type="video/ogg" />
                                Your browser does not support the video tag.
                            </video>
                        </Col>
                        <Col lg={3} md={6}>
                            <video style={{ width: 100 + '%' }} controls >
                                <source src="img/video.mp4" />
                                <source src="" type="video/ogg" />
                                Your browser does not support the video tag.
                            </video>
                        </Col>
                        <Col lg={3} md={6}>
                            <video style={{ width: 100 + '%' }} controls >
                                <source src="img/video.mp4" />
                                <source src="" type="video/ogg" />
                                Your browser does not support the video tag.
                            </video>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    )
}
